var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        visible: _vm.drawer,
        size: 720,
        title: `${_vm.title}的操作日志`,
        "append-to-body": true,
        modal: false,
        "before-close": _vm.handleClose,
        wrapperClosable: true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "log-body" },
        [
          _c("BaseTable", {
            attrs: {
              tableData: _vm.tableData,
              pageIndex: _vm.pagerConfig.pageIndex,
              pageSize: _vm.pagerConfig.pageSize,
              loading: _vm.loading,
            },
            on: { handlePageChange: _vm.handlePageChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tableData.columns.indexOf(row.scope) + 1) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }