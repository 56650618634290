import { render, staticRenderFns } from "./AddUserDialog.vue?vue&type=template&id=188d7c51&scoped=true&"
import script from "./AddUserDialog.vue?vue&type=script&lang=js&"
export * from "./AddUserDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddUserDialog.vue?vue&type=style&index=0&id=188d7c51&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188d7c51",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git\\kjp_oms_be\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('188d7c51')) {
      api.createRecord('188d7c51', component.options)
    } else {
      api.reload('188d7c51', component.options)
    }
    module.hot.accept("./AddUserDialog.vue?vue&type=template&id=188d7c51&scoped=true&", function () {
      api.rerender('188d7c51', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/components/AddUserDialog.vue"
export default component.exports