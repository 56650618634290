<template>
  <el-dialog
    v-drag
    :title="`${editData.nameCH}的销售目标`"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="5vh"
  >
    <div class="content">
      <div class="opt">
        <div class="add flex_c_c" @click="dialogVisible1 = true">
          <svg-icon className="icon" iconClass="add" />新增
        </div>
      </div>
      <div class="data">
        <BaseTable
          :height="440"
          :tableData="tableData"
          :loading="loading"
          :pageIndex="pagerConfig.pageIndex"
          :pageSize="pagerConfig.pageSize"
          :border="true"
        >
          <template slot="salesAmount" slot-scope="row">
            <div class="panel">
              <div
                class="target"
                :class="{
                  success:
                    row.scope.salesAmount >= row.scope.estimateSalesAmount,
                  error: row.scope.salesAmount < row.scope.estimateSalesAmount,
                }"
              >
                <div class="tips">
                  {{
                    row.scope.salesAmount >= row.scope.estimateSalesAmount
                      ? "未达成"
                      : "未达成"
                  }}
                </div>
                <span class="num">
                  {{
                    (
                      (row.scope.salesAmount / row.scope.estimateSalesAmount) *
                      100
                    ).toFixed(1)
                  }}%
                </span>
              </div>
              <span class="value">
                <span
                  :class="{
                    'value-success':
                      row.scope.salesAmount >= row.scope.estimateSalesAmount,
                    'value-error':
                      row.scope.salesAmount < row.scope.estimateSalesAmount,
                  }"
                >
                  {{ row.scope.salesAmount || 0 }}
                </span>
                / {{ row.scope.estimateSalesAmount }}
              </span>
            </div>
          </template>
          <template slot="customerCount" slot-scope="row">
            <div class="panel">
              <div
                class="target"
                :class="{
                  success:
                    row.scope.customerCount >= row.scope.estimateCustomerCount,
                  error:
                    row.scope.customerCount < row.scope.estimateCustomerCount,
                }"
              >
                <div class="tips">
                  {{
                    row.scope.customerCount >= row.scope.estimateCustomerCount
                      ? "未达成"
                      : "未达成"
                  }}
                </div>
                <span class="num">
                  {{
                    (
                      (row.scope.customerCount /
                        row.scope.estimateCustomerCount) *
                      100
                    ).toFixed(1)
                  }}%
                </span>
              </div>
              <span class="value">
                <span
                  :class="{
                    'value-success':
                      row.scope.salesAmount >= row.scope.estimateCustomerCount,
                    'value-error':
                      row.scope.customerCount < row.scope.estimateCustomerCount,
                  }"
                >
                  {{ row.scope.customerCount || 0 }}
                </span>
                / {{ row.scope.estimateCustomerCount }}
              </span>
            </div>
          </template>
          <template slot="opt" slot-scope="row">
            <div class="table-opt">
              <img
                v-if="checkDate(row.scope.cycleEndDate)"
                class="delete"
                title="修改"
                src="@/assets/images/const/edit.png"
                @click="editRow(row.scope)"
              />
              <img
                v-if="checkDate(row.scope.cycleStartDate)"
                class="delete"
                title="删除"
                src="@/assets/images/const/delete.png"
                @click="deleteRow(row.scope)"
              />
              <span
                v-if="
                  !checkDate(row.scope.cycleEndDate) &&
                    !checkDate(row.scope.cycleEndDate)
                "
              >-</span
              >
            </div>
          </template>
        </BaseTable>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
    <AddSaleTagetDialog
      :dialogVisible="dialogVisible1"
      :editData="rowData"
      :salesUserId="editData.id"
      @cancel="AddCancel"
      @ok="addOk"
    ></AddSaleTagetDialog>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import AddSaleTagetDialog from './AddSaleTagetDialog'
import { getSalesTargetPages, delSalesTarget } from '@/api/system'
import dayjs from 'dayjs'
export default {
  components: { BaseTable, AddSaleTagetDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'cycleDate',
            label: '周期',
            width: 220,
            formatter: (row) => {
              return `${row.cycleStartDate.slice(
                0,
                10
              )} ~ ${row.cycleEndDate.slice(0, 10)}`
            }
          },
          {
            filed: 'salesAmount',
            label: '销售额（万USD）',
            width: 344,
            isCustom: true
          },
          {
            filed: 'customerCount',
            label: '新客户（家）',
            width: 344,
            isCustom: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      dialogVisible1: false,
      rowData: {}
    }
  },
  watch: {
    dialogVisible(val) {
      if (val && this.editData.id) {
        this.getList(this.pagerConfig)
      }
    }
  },
  methods: {
    async getList(params) {
      this.loading = true
      params = {
        ...params,
        queryParam: {
          userId: this.editData.id
        }
      }
      try {
        const res = await getSalesTargetPages(params)
        if (res.success) {
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
          this.loading = false
        } else {
          this.$message.error(res.errorMessage)
          this.loading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getLists(params)
    },
    cancel() {
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.$emit('cancel')
    },
    ok() {
      this.$emit('ok')
    },
    checkDate(date) {
      const newDate = new Date()
      return dayjs(date).isAfter(newDate)
    },
    editRow(row) {
      this.rowData = row
      this.dialogVisible1 = true
    },
    async deleteRow(row) {
      this.$confirm('此操作将永久删除该销售目标, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(async() => {
          try {
            const res = await delSalesTarget({ id: row.id })
            if (res.success) {
              this.getList()
              this.$message.success('删除成功')
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.error(error)
          }
        })
        .catch(() => {})
    },
    addOk() {
      this.getList()
      this.dialogVisible1 = false
    },
    AddCancel() {
      this.rowData = {}
      this.dialogVisible1 = false
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  height: auto;
  margin: 0 auto;
  .opt {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    & div {
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
      svg {
        font-size: 20px;
      }
    }
    .add {
      width: 80px;
      background: #35ba83;
    }
  }
  .data {
    width: 100%;
    height: auto;
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
      .fp-cell {
        border: 0;
      }
    }
    .panel {
      display: flex;
    }
    .target {
      width: 80px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
      display: flex;
      .tips {
        width: 40px;
        height: 20px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        text-align: center;
        margin: 3px;
        font-size: 12px;
      }
      .num {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        line-height: 26px;
      }
    }
    .success {
      background: rgba(53, 186, 131, 0.15);
      .tips {
        background: #35ba83;
      }
      .num {
        color: #35ba83;
      }
    }
    .error {
      background: rgba(254, 69, 61, 0.15);
      .tips {
        background: #fe453d;
      }
      .num {
        color: #fe453d;
      }
    }
    .value {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 26px;
      .value-error {
        color: rgba(254, 69, 61, 1);
      }
      .value-success {
        color: rgba(53, 186, 131, 1);
      }
    }
    .delete {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .table-opt {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
