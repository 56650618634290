<template>
  <div class="view-contanier">
    <div class="opt">
      <div class="tab">
        <span
          v-permissson="'QB'"
          class="tabItem"
          :class="{ active: active === 0 }"
          @click="changeType(0, 'QB')"
        >全部</span
        >
        <span
          v-permissson="'XS'"
          class="tabItem"
          :class="{ active: active === 1 }"
          @click="changeType(1, 'XS')"
        >销售</span
        >
      </div>
      <div style="display: flex">
        <div v-show="newAdd" :v-permissson="newAdd" class="btn" @click="add">
          <svg-icon className="icon" iconClass="add" />新增
        </div>
        <BaseFilter
          :isActive="isActive"
          :width="438"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form ref="form" :model="filterList" label-width="40px">
              <el-form-item label="搜索">
                <el-input
                  v-model="filterList.search"
                  style="width: 320px"
                  placeholder="请输入中文名/英文名/电话/邮箱"
                ></el-input>
              </el-form-item>
              <el-form-item label="状态">
                <div class="select">
                  <el-select
                    v-model="filterList.isActived"
                    style="width: 320px"
                    placeholder="请选择状态"
                  >
                    <el-option
                      v-for="item in activeOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </BaseFilter>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="{
          ...tableData,
          fileds,
        }"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
      >
        <template slot="isActived" slot-scope="row">
          <div
            class="circle"
            :style="{
              'background-color': row.scope.isActived ? ' #35BA83' : '#FE453D',
            }"
          ></div>
          {{ row.scope.isActived ? "使用中" : "未使用" }}
        </template>
        <template slot="userRole" slot-scope="row">
          <el-tag v-for="(item, $index) in row.scope.userRole" :key="$index">{{
            item.key
          }}</el-tag>
        </template>
        <template slot="saleTargetForIndex" slot-scope="row">
          <p style="text-align: left">
            <span class="lable">销售额</span>
            <span
              class="value"
            >{{ row.scope.salesTarget?.estimateSalesAmount || 0 }} 万USD</span
            >
          </p>
          <p style="text-align: left">
            <span class="lable">新客户</span>
            <span
              class="value"
            >{{ row.scope.salesTarget?.estimateCustomerCount || 0 }} 家</span
            >
          </p>
        </template>
        <template slot="saleTarget" slot-scope="row">
          <p style="text-align: left">
            <span
              class="lable1"
              :class="{
                success:
                  sumPercent(
                    row.scope.salesTarget?.salesAmount,
                    row.scope.salesTarget?.estimateSalesAmount
                  ) >= 1,
                error:
                  sumPercent(
                    row.scope.salesTarget?.salesAmount,
                    row.scope.salesTarget?.estimateSalesAmount
                  ) < 1,
              }"
            >
              销售额
              <span>
                {{
                  `${sumPercent(
                    row.scope.salesTarget?.salesAmount,
                    row.scope.salesTarget?.estimateSalesAmount
                  )}%`
                }}
              </span>
            </span>
            <span class="value">{{ row.scope.salesTarget?.salesAmount || 0 }} 万USD</span>
          </p>
          <p style="text-align: left">
            <span
              class="lable1"
              :class="{
                success:
                  sumPercent(
                    row.scope.salesTarget?.customerCount,
                    row.scope.salesTarget?.estimateCustomerCount
                  ) >= 1,
                error:
                  sumPercent(
                    row.scope.salesTarget?.customerCount,
                    row.scope.salesTarget?.estimateCustomerCount
                  ) < 1,
              }"
            >
              新客户
              <span>
                {{
                  `${sumPercent(
                    row.scope.salesTarget?.customerCount,
                    row.scope.salesTarget?.estimateCustomerCount
                  )}%`
                }}
              </span>
            </span>
            <span class="value">{{ row.scope.salesTarget?.customerCount || 0 }} 家</span>
          </p>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <el-button
              v-permissson="'update'"
              type="text"
              @click="update(row.scope)"
            >修改</el-button
            >
            <el-button
              v-permissson="'opslog'"
              type="text"
              @click="log(row.scope)"
            >操作日志</el-button
            >
            <el-button
              v-show="active === 1"
              v-permissson="'salesTarget'"
              type="text"
              @click="saleTarget(row.scope)"
            >销售目标</el-button
            >
          </div>
        </template>
      </BaseTable>
    </div>
    <add-user-dialog
      :dialogVisible="dialogVisible"
      :editData="editData"
      @ok="ok"
      @cancel="cancel"
    ></add-user-dialog>
    <user-log-drawer
      :drawer="drawer"
      :title="editData.nameCH"
      :userId="editData.id"
      @close="close"
    ></user-log-drawer>
    <SaleTargetDialog
      :dialogVisible="dialogVisible1"
      :editData="editData"
      @ok="ok"
      @cancel="dialogVisible1 = false"
    ></SaleTargetDialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')
import { getUserPages } from '@/api/setting'
import AddUserDialog from './components/AddUserDialog.vue'
import { mapGetters } from 'vuex'
import { permissions } from '@/utils/common'
import UserLogDrawer from './components/UserLogDrawer.vue'
import SaleTargetDialog from './components/SaleTargetDialog.vue'
import { myMixins } from '@/mixins'
export default {
  name: 'UserOpt',
  components: {
    BaseTable,
    AddUserDialog,
    UserLogDrawer,
    BaseFilter,
    SaleTargetDialog
  },
  mixins: [myMixins],
  data() {
    return {
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      tableData: {
        fileds: [],
        columns: [],
        total: 0
      },
      height: 0,
      dialogVisible: false,
      dialogVisible1: false,
      drawer: false,
      editData: {},
      filterList: {
        search: '',
        isActived: ''
      },
      active: 0,
      activeCode: '',
      isActive: false,
      activeOption: [
        {
          value: true,
          label: '使用中'
        },
        {
          value: false,
          label: '未使用'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    newAdd() {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const presentTab = menu.children.find((a) => a.code === this.activeCode)
      const code = presentTab?.children?.find((a) => a.nameCH === '新增')?.code
      return code || ''
    },
    fileds() {
      const obj = {
        0: [
          {
            filed: 'code',
            label: '编码',
            width: '100px',
            fixed: 'left'
          },
          {
            filed: 'nameCH',
            label: '中文名',
            width: '120px'
          },
          {
            filed: 'nameEN',
            label: '英文名',
            width: '120px'
          },
          {
            filed: 'phone',
            label: '电话',
            width: '140px'
          },
          {
            filed: 'email',
            label: '邮箱',
            width: '180px'
          },
          {
            filed: 'countryId',
            label: '地点',
            width: '120px',
            formatter: (row) => {
              let str = ''
              if (this.addressDropOption) {
                const addressObj = this.addressDropOption.find(
                  (a) => a.value === row.countryId
                )
                str = addressObj.label
              }
              return str
            }
          },
          {
            filed: 'userRole',
            label: '角色',
            width: '300',
            isCustom: true
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '100px',
            isCustom: true
          },
          {
            filed: 'type',
            label: '用户类型',
            width: '100px'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '150px',
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '100px'
          },
          {
            filed: 'updatedOn',
            label: '修改时间',
            width: '150px',
            formatter: (row) =>
              row.updatedOn
                ? row.updatedOn.slice(0, row.updatedOn.length - 3)
                : ''
          },
          {
            filed: 'updatedName',
            label: '修改人',
            width: '100px'
          },
          {
            filed: 'lastLoginDate',
            label: '最后登录日期',
            width: '150px',
            formatter: (row) =>
              row.lastLoginDate
                ? row.lastLoginDate.slice(0, row.lastLoginDate.length - 3)
                : ''
          },
          {
            filed: 'opt',
            label: '操作',
            width: '140px',
            isCustom: true,
            fixed: 'right'
          }
        ],
        1: [
          {
            filed: 'code',
            label: '编码',
            width: '100px',
            fixed: 'left'
          },
          {
            filed: 'nameCH',
            label: '中文名',
            width: '120px'
          },
          {
            filed: 'nameEN',
            label: '英文名',
            width: '120px'
          },
          {
            filed: 'phone',
            label: '电话',
            width: '140px'
          },
          {
            filed: 'email',
            label: '邮箱',
            width: '180px'
          },
          {
            filed: 'countryId',
            label: '地点',
            width: '120px',
            formatter: (row) => {
              let str = ''
              if (this.addressDropOption) {
                const addressObj = this.addressDropOption.find(
                  (a) => a.value === row.countryId
                )
                str = addressObj.label
              }
              return str
            }
          },
          {
            filed: 'userRole',
            label: '角色',
            width: '300',
            isCustom: true
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '100px',
            isCustom: true
          },
          {
            filed: 'type',
            label: '用户类型',
            width: '100px'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '150px',
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '100px'
          },
          {
            filed: 'updatedOn',
            label: '修改时间',
            width: '150px',
            formatter: (row) =>
              row.updatedOn
                ? row.updatedOn.slice(0, row.updatedOn.length - 3)
                : ''
          },
          {
            filed: 'updatedName',
            label: '修改人',
            width: '100px'
          },
          {
            filed: 'lastLoginDate',
            label: '最后登录日期',
            width: '150px',
            formatter: (row) =>
              row.lastLoginDate
                ? row.lastLoginDate.slice(0, row.lastLoginDate.length - 3)
                : ''
          },
          {
            filed: 'saleTargetForIndex',
            label: '当月销售目标',
            width: '284',
            isCustom: true
          },
          {
            filed: 'saleTarget',
            label: '当月销售达成情况',
            width: '284',
            isCustom: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '200px',
            isCustom: true,
            fixed: 'right'
          }
        ]
      }
      return obj[this.active]
    }
  },
  created() {
    const menuList = this.$store.getters.initRouter
    const menu = permissions(this.$route.meta.code, menuList)
    const codeList = ['QB', 'XS']
    const childrenCode = menu.children.map(a => a.code)
    this.active = codeList.findIndex((a) => childrenCode.includes(a))
    this.activeCode = menu.children[0].code
    this.getList(this.pagerConfig)
  },
  mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    changeType(type, code) {
      this.active = type
      this.activeCode = code
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      this.getList(this.pagerConfig)
    },
    getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.filterList,
            isSale: this.active === 1
          }
        }
        getUserPages(params).then((res) => {
          if (res.success) {
            this.loading = false
            this.tableData.total = res.data.total
            this.tableData.columns = res.data.list
          } else {
            this.loading = false
            this.$message.error(res.errorMessage)
          }
        })
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    update(row) {
      this.editData = row
      this.dialogVisible = true
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getList(params)
    },
    add() {
      this.dialogVisible = true
    },
    ok() {
      this.getList(this.pagerConfig)
      this.dialogVisible = false
      this.dialogVisible1 = false
      this.editData = {}
    },
    cancel() {
      this.dialogVisible = false
      this.editData = {}
    },
    log(row) {
      this.editData = row
      this.drawer = true
    },
    close() {
      this.editData = {}
      this.drawer = false
    },
    sendFilter() {
      if (this.filterList.search !== '' || this.filterList.isActived !== '') {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    reset() {
      this.filterList = {
        search: '',
        isActived: ''
      }
      this.isActive = false
    },
    sumPercent(a, b) {
      return ((a || 0 / b || 0) * 100).toFixed(2)
    },
    saleTarget(row) {
      this.editData = row
      this.dialogVisible1 = true
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabItem {
      padding: 20px;
      width: 42px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
    }
    .active {
      color: #2395f3;
    }
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #2395f3;
    border-radius: 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .lable {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      background-color: rgba(153, 153, 153, 0.2);
      padding: 2px 7px;
    }
    .lable1 {
      display: inline-block;
      width: 80px;
      height: 20px;
      border-radius: 2px 2px 2px 2px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
    .error {
      color: #fe453d;
      background-color: rgba(254, 69, 61, 0.2);
    }
    .success {
      color: rgba(53, 186, 131, 1);
      background-color: rgba(53, 186, 131, 0.2);
    }
    .value {
      font-size: 14px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #666666;
      line-height: 20px;
      margin-left: 10px;
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .table-opt:deep(.el-button) {
    padding: 0;
  }
  .filter {
    width: 438px;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
}
.select {
  width: 100%;
  &:deep(.el-select) {
    width: 100%;
  }
}
</style>
