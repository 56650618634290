var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.editData.id ? "修改" : "新增",
        visible: _vm.dialogVisible,
        width: "800px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "cycleDate", label: "选择周期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              type: "daterange",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                              "unlink-panels": "",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.form.cycleDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cycleDate", $$v)
                              },
                              expression: "form.cycleDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "estimateSalesAmount",
                            label: "销售额",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请输入销售额" },
                              on: {
                                input: function ($event) {
                                  return _vm.onInput("estimateSalesAmount")
                                },
                              },
                              model: {
                                value: _vm.form.estimateSalesAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "estimateSalesAmount", $$v)
                                },
                                expression: "form.estimateSalesAmount",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "suffix",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [_vm._v("万USD")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "estimateCustomerCount",
                            label: "新客户",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请输入新客户" },
                              on: {
                                input: function ($event) {
                                  return _vm.onInput("estimateCustomerCount")
                                },
                              },
                              model: {
                                value: _vm.form.estimateCustomerCount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "estimateCustomerCount",
                                    $$v
                                  )
                                },
                                expression: "form.estimateCustomerCount",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "suffix",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [_vm._v("家")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }