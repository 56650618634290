<template>
  <el-drawer
    :visible.sync="drawer"
    :size="720"
    :title="`${title}的操作日志`"
    :append-to-body="true"
    :modal="false"
    :before-close="handleClose"
    :wrapperClosable="true"
    @close="handleClose"
  >
    <div class="log-body">
      <BaseTable
        :tableData="tableData"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange">
        <template slot="index" slot-scope="row">
          {{ tableData.columns.indexOf(row.scope) + 1 }}
        </template>
      </BaseTable>
    </div>
  </el-drawer>
</template>

<script>
import { getUserLogPages } from '@/api/system'

const BaseTable = () => import('@/components/table/BaseTable.vue')
export default {
    components: { BaseTable },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'index',
            label: '序号',
            width: '100px',
            isCustom: true
          },
          {
            filed: 'operation',
            label: '操作类型',
            width: '120px'
          },
          {
            filed: 'createdOn',
            label: '操作时间',
            width: '160px',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          },
          {
            filed: 'description',
            label: '描述'
          }
        ],
        columns: [],
        total: 0
      }
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getLists(this.pagerConfig)
      }
    }
  },
  methods: {
    getLists(param) {
      param = {
        ...param,
        queryParam: { userId: this.userId }
      }
      getUserLogPages(param).then((res) => {
        if (res.success) {
            this.tableData.columns = res.data.list.reverse()
            this.tableData.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getLists(params)
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.log-body {
  width: calc(100% - 40px);
  height: 100%;
  margin: 20px auto 0;
}
</style>
