<template>
  <el-dialog
    v-drag
    :title="editData.id ? '修改' : '新增'"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item prop="cycleDate" label="选择周期">
              <el-date-picker
                v-model="form.cycleDate"
                type="daterange"
                style="width: 320px;"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                unlink-panels
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="estimateSalesAmount" label="销售额">
              <el-input
                v-model="form.estimateSalesAmount"
                style="width: 320px;"
                placeholder="请输入销售额"
                @input="onInput('estimateSalesAmount')">
                <span slot="suffix" class="suffix">万USD</span>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="estimateCustomerCount" label="新客户">
              <el-input
                v-model="form.estimateCustomerCount"
                style="width: 320px;"
                placeholder="请输入新客户"
                @input="onInput('estimateCustomerCount')">
                <span slot="suffix" class="suffix">家</span>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>
  <script>
  import { changeSalesTarget } from '@/api/system'
  export default {
    props: {
      dialogVisible: {
        type: Boolean,
        default: false
      },
      editData: {
        type: Object,
        default: function() {
          return {}
        }
      },
      salesUserId: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        form: {
          cycleDate: '',
          estimateSalesAmount: '',
          estimateCustomerCount: ''
        },
        rules: {
          estimateSalesAmount: [
            { required: true, message: '预计销售金额', trigger: 'blur' }
          ],
          estimateCustomerCount: [
            { required: true, message: '预计增加新客户', trigger: 'blur' }
          ],
          cycleDate: [
            { required: true, message: '请选择周期日期', trigger: 'change' }
          ]
        },
        dialogVisible2: false,
        commData: [],
        typeOption: [
          {
            value: 0,
            label: '应收'
          },
          {
            value: 1,
            label: '应付'
          }
        ],
        sourceOption: [],
        destinationOption: []
      }
    },
    computed: {
    },
    watch: {
      dialogVisible: function(val) {
        if (val) {
          if (this.editData.id) {
            this.form = {
              ...this.editData,
              cycleDate: [this.editData.cycleStartDate, this.editData.cycleEndDate]
            }
          } else {
            this.$refs['form']?.resetFields()
            this.form = {
              cycleDate: '',
              estimateSalesAmount: '',
              estimateCustomerCount: ''
            }
          }
        }
      }
    },
    created() {
    },
    methods: {
      onInput(type) {
        type === 'estimateSalesAmount' &&
          this.$set(
            this.form,
            'estimateSalesAmount',
            this.form.estimateSalesAmount.replace(/[^0-9.]/g, '')
          )
        type === 'estimateCustomerCount' &&
          this.$set(this.form, 'estimateCustomerCount', this.form.estimateCustomerCount.replace(/[^0-9]/g, ''))
      },
      cancel() {
        this.$refs['form']?.resetFields()
        this.$emit('cancel')
      },
      ok(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              const params = {
                ...this.form,
                cycleStartDate: this.form.cycleDate[0],
                cycleEndDate: this.form.cycleDate[1],
                salesUserId: this.salesUserId
              }
              changeSalesTarget(params).then((res) => {
                if (res.success) {
                  if (!this.form.id) {
                    this.$message.success('新增成功')
                  } else {
                    this.$message.success('修改成功')
                  }
                  this.$emit('ok')
                } else {
                  this.$message.error(res.errorMessage)
                }
              })
          }
        })
      }
    }
  }
  </script>
  <style lang="less" scoped>
  .content {
    width: calc(100% - 80px);
    height: auto;
    margin: 0 auto;
    text-align: left;
    .form {
      display: flex;
      width: 100%;
      height: auto;
      flex-wrap: wrap;
      &:deep(.el-form-item__label) {
        line-height: 30px
      }
      .suffix {
        font-size: 14px;
        color: #666666;
        margin-right: 10px
      }
    }
  }
  /deep/ .el-dialog__body {
    padding: 20px 20px;
  }
  .footers {
    width: 100%;
    display: flex;
    justify-content: center;
    .cancel {
      width: 120px;
      height: 40px;
      background: #EEEEEE;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-right: 16px;
      cursor: pointer;
    }
    .ok {
      width: 120px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      cursor: pointer;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .title {
    margin-top: 30px;
    margin-block: 16px;
  }
  </style>

