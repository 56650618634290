var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "opt" }, [
        _c("div", { staticClass: "tab" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "QB",
                  expression: "'QB'",
                },
              ],
              staticClass: "tabItem",
              class: { active: _vm.active === 0 },
              on: {
                click: function ($event) {
                  return _vm.changeType(0, "QB")
                },
              },
            },
            [_vm._v("全部")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "XS",
                  expression: "'XS'",
                },
              ],
              staticClass: "tabItem",
              class: { active: _vm.active === 1 },
              on: {
                click: function ($event) {
                  return _vm.changeType(1, "XS")
                },
              },
            },
            [_vm._v("销售")]
          ),
        ]),
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.newAdd,
                    expression: "newAdd",
                  },
                ],
                staticClass: "btn",
                attrs: { "v-permissson": _vm.newAdd },
                on: { click: _vm.add },
              },
              [
                _c("svg-icon", {
                  attrs: { className: "icon", iconClass: "add" },
                }),
                _vm._v("新增 "),
              ],
              1
            ),
            _c(
              "BaseFilter",
              {
                attrs: { isActive: _vm.isActive, width: 438 },
                on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
              },
              [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.filterList, "label-width": "40px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "搜索" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "320px" },
                              attrs: {
                                placeholder: "请输入中文名/英文名/电话/邮箱",
                              },
                              model: {
                                value: _vm.filterList.search,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "search", $$v)
                                },
                                expression: "filterList.search",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-form-item", { attrs: { label: "状态" } }, [
                          _c(
                            "div",
                            { staticClass: "select" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "320px" },
                                  attrs: { placeholder: "请选择状态" },
                                  model: {
                                    value: _vm.filterList.isActived,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filterList, "isActived", $$v)
                                    },
                                    expression: "filterList.isActived",
                                  },
                                },
                                _vm._l(_vm.activeOption, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: {
                    ..._vm.tableData,
                    fileds: _vm.fileds,
                  },
                  pageIndex: _vm.pagerConfig.pageIndex,
                  pageSize: _vm.pagerConfig.pageSize,
                  loading: _vm.loading,
                },
                on: { handlePageChange: _vm.handlePageChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "isActived",
                      fn: function (row) {
                        return [
                          _c("div", {
                            staticClass: "circle",
                            style: {
                              "background-color": row.scope.isActived
                                ? " #35BA83"
                                : "#FE453D",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                row.scope.isActived ? "使用中" : "未使用"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "userRole",
                      fn: function (row) {
                        return _vm._l(
                          row.scope.userRole,
                          function (item, $index) {
                            return _c("el-tag", { key: $index }, [
                              _vm._v(_vm._s(item.key)),
                            ])
                          }
                        )
                      },
                    },
                    {
                      key: "saleTargetForIndex",
                      fn: function (row) {
                        return [
                          _c("p", { staticStyle: { "text-align": "left" } }, [
                            _c("span", { staticClass: "lable" }, [
                              _vm._v("销售额"),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  row.scope.salesTarget?.estimateSalesAmount ||
                                    0
                                ) + " 万USD"
                              ),
                            ]),
                          ]),
                          _c("p", { staticStyle: { "text-align": "left" } }, [
                            _c("span", { staticClass: "lable" }, [
                              _vm._v("新客户"),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  row.scope.salesTarget
                                    ?.estimateCustomerCount || 0
                                ) + " 家"
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "saleTarget",
                      fn: function (row) {
                        return [
                          _c("p", { staticStyle: { "text-align": "left" } }, [
                            _c(
                              "span",
                              {
                                staticClass: "lable1",
                                class: {
                                  success:
                                    _vm.sumPercent(
                                      row.scope.salesTarget?.salesAmount,
                                      row.scope.salesTarget?.estimateSalesAmount
                                    ) >= 1,
                                  error:
                                    _vm.sumPercent(
                                      row.scope.salesTarget?.salesAmount,
                                      row.scope.salesTarget?.estimateSalesAmount
                                    ) < 1,
                                },
                              },
                              [
                                _vm._v(" 销售额 "),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.sumPercent(
                                          row.scope.salesTarget?.salesAmount,
                                          row.scope.salesTarget
                                            ?.estimateSalesAmount
                                        )}%`
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  row.scope.salesTarget?.salesAmount || 0
                                ) + " 万USD"
                              ),
                            ]),
                          ]),
                          _c("p", { staticStyle: { "text-align": "left" } }, [
                            _c(
                              "span",
                              {
                                staticClass: "lable1",
                                class: {
                                  success:
                                    _vm.sumPercent(
                                      row.scope.salesTarget?.customerCount,
                                      row.scope.salesTarget
                                        ?.estimateCustomerCount
                                    ) >= 1,
                                  error:
                                    _vm.sumPercent(
                                      row.scope.salesTarget?.customerCount,
                                      row.scope.salesTarget
                                        ?.estimateCustomerCount
                                    ) < 1,
                                },
                              },
                              [
                                _vm._v(" 新客户 "),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.sumPercent(
                                          row.scope.salesTarget?.customerCount,
                                          row.scope.salesTarget
                                            ?.estimateCustomerCount
                                        )}%`
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  row.scope.salesTarget?.customerCount || 0
                                ) + " 家"
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "table-opt" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "update",
                                      expression: "'update'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.update(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "opslog",
                                      expression: "'opslog'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.log(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("操作日志")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.active === 1,
                                      expression: "active === 1",
                                    },
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "salesTarget",
                                      expression: "'salesTarget'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saleTarget(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("销售目标")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3355312532
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("add-user-dialog", {
        attrs: { dialogVisible: _vm.dialogVisible, editData: _vm.editData },
        on: { ok: _vm.ok, cancel: _vm.cancel },
      }),
      _c("user-log-drawer", {
        attrs: {
          drawer: _vm.drawer,
          title: _vm.editData.nameCH,
          userId: _vm.editData.id,
        },
        on: { close: _vm.close },
      }),
      _c("SaleTargetDialog", {
        attrs: { dialogVisible: _vm.dialogVisible1, editData: _vm.editData },
        on: {
          ok: _vm.ok,
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }