<template>
  <el-dialog
    v-drag
    :title="editData.id ? '修改' : '新增'"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="4%"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>中文名</label>
          <el-form-item prop="nameCH">
            <el-input
              v-model="form.nameCH"
              placeholder="请输入中文名"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable">英文名</label>
          <el-form-item prop="nameEN">
            <el-input
              v-model="form.nameEN"
              placeholder="请输入英文名"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>电话</label>
          <el-form-item prop="phone">
            <el-input v-model="form.phone" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入电话" maxlength="11"></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>邮箱</label>
          <el-form-item prop="email">
            <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
        </div>
        <div v-if="!form.id" class="form-item">
          <label class="lable"><span class="red">* </span> 密码</label>
          <el-form-item prop="password">
            <el-input
              v-model="form.password"
              autocomplete="off"
              :type="showEye1 ? 'text' : 'password'"
              placeholder="密码长度6-18位，且必须包含数字和字母"
            >
              <span slot="suffix" class="suffixEye" @click="showEye1 = !showEye1">
                <svg-icon v-show="!showEye1" className="openEye" iconClass="openEye" />
                <svg-icon v-show="showEye1" className="closeEye" iconClass="closeEye" />
              </span>
            </el-input>
          </el-form-item>
        </div>
        <div v-if="!form.id" class="form-item">
          <label class="lable"><span class="red">* </span>再次输入密码</label>
          <el-form-item prop="password2">
            <el-input
              v-model="form.password2"
              :type="showEye2 ? 'text' : 'password'"
              placeholder="密码长度6-18位，且必须包含数字和字母"
            >
              <span slot="suffix" class="suffixEye" @click="showEye2 = !showEye2">
                <svg-icon v-show="!showEye2" className="openEye" iconClass="openEye" />
                <svg-icon v-show="showEye2" className="closeEye" iconClass="closeEye" />
              </span>
            </el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>地点</label>
          <el-form-item prop="countryId">
            <el-select v-model="form.countryId" placeholder="请选择国家">
              <el-option
                v-for="(item, $index) in addressDropOption"
                :key="$index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>权限组</label>
          <el-form-item prop="userRoleId">
            <el-select
              v-model="form.userRoleId"
              multiple
              placeholder="请选择权限组"
            >
              <el-option
                v-for="(item, $index) in userRoleIdOption"
                :key="$index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>用户类型</label>
          <el-form-item prop="type">
            <el-select
              v-model="form.type"
              placeholder="请选择用户类型"
              @change="changeUserType"
            >
              <el-option
                v-for="(item, $index) in userTypeOptions"
                :key="$index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item" style="display: flex">
          <div>
            <label class="lable"><span class="red">* </span>状态</label>
            <el-form-item prop="isActived">
              <el-switch
                v-model="form.isActived"
                active-color="#2395F3"
                inactive-color="#f1f1f1"
                :active-text="form.isActived ? '启用' : '禁用'"
              >
              </el-switch>
            </el-form-item>
          </div>
          <div style="margin-left: 40px">
            <label class="lable"><span class="red">* </span>申付权限</label>
            <el-form-item prop="isDefrayPermission">
              <el-switch
                v-model="form.isDefrayPermission"
                active-color="#2395F3"
                inactive-color="#f1f1f1"
                :active-text="form.isDefrayPermission ? '启用' : '禁用'"
              >
              </el-switch>
            </el-form-item>
          </div>
        </div>
        <div v-if="isSaler" class="form-item">
          <label class="lable"><span class="red">* </span>业务组别</label>
          <el-form-item prop="saleGroupValue">
            <el-select
              v-model="form.saleGroupValue"
              style="width: 260px"
              placeholder="请选择业务组别"
            >
              <el-option
                v-for="(item, $index) in commOptions"
                :key="$index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="addClass" @click="addClass">管理</span>
          </el-form-item>
        </div>
        <div v-if="form.isDefrayPermission" class="form-item">
          <label class="lable"><span class="red">* </span>申付权限角色</label>
          <el-form-item prop="defrayPermissionRole">
            <el-select
              v-model="form.defrayPermissionRole"
              placeholder="请选择申付权限角色"
            >
              <el-option label="创建人" :value="0"></el-option>
              <el-option label="主管" :value="5"></el-option>
              <el-option label="经理" :value="10"></el-option>
              <el-option label="总监" :value="15"></el-option>
              <el-option label="财务" :value="20"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="form.isDefrayPermission" class="form-item">
          <label class="lable"><span class="red">* </span>签名上传</label>
          <el-form-item prop="signFileGuid">
            <el-upload
              class="upload-demo"
              :action="`/backEnd/api/${config.supplyCode}/File/uploadFile?source=6`"
              :on-success="(e) => handleSuccess(e)"
              :on-error="(e) => handleError(e)"
              :before-upload="(e) => beforeUpload(e)"
              :show-file-list="false"
            >
              <div class="upload flex_c_c">
                <i class="el-icon-plus"></i>
              </div>
              <div
                v-if="form.signFileGuid"
                slot="tip"
                class="display"
              >
                <img
                  :src="`/backEnd/api/${config.supplyCode}/File/preview?guid=${form.signFileGuid}`"
                />
              </div>
              <img v-if="form.signFileGuid" class="trigger" src="@/assets/images/const/edit.png" />
            </el-upload>
          </el-form-item>
          <img v-if="form.signFileGuid" class="look" src="@/assets/images/system/look.png" @click="previewImg" />
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
    <CommDialog
      :dialogVisible="dialogVisible2"
      :dataColumn="commOptions"
      title="销售分组"
      :type="10"
      @ok="cancel2"
      @cancel="cancel2"
      @dataChange="dataChange"
    ></CommDialog>
    <!-- <el-dialog
      title="查看签名"
      :visible.sync="dialogVisible3"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      :before-close="() => (dialogVisible3 = false)"
    >
      <div class="dialog-contanier">
        <img :src="">
      </div>
      <span slot="footer" class="flex_c_c">
        <div class="cancel flex_c_c" @click="dialogVisible3 = false">取消</div>
      </span>
    </el-dialog> -->
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { isValidPwd, isValidPhone, isValidEmail } from '@/utils/validate'
import { validationPhoneEmail, addUser, updateUser } from '@/api/system'
import { getSysParams } from '@/api/system'
import { getKey } from '@/utils/common'
import { Encrypt } from '@/utils/secret'
import config from '@/utils/config'
const CommDialog = () => import('@/components/dialog/CommDialog')
export default {
  components: { CommDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (isValidPwd(value)) {
          callback()
        } else {
          callback(new Error('密码长度6-18位，且必须包含数字和字母'))
        }
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      if (!isValidPhone(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        if (!this.form.id) {
          this.checkPhone(value, callback)
        } else {
          callback()
        }
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (!isValidEmail(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        if (!this.form.id) {
          this.checkEmail(value, callback)
        } else {
          callback()
        }
      }
    }
    return {
      config,
      form: {
        type: '',
        nameCH: '',
        password: '',
        password2: '',
        nameEN: '',
        phone: '',
        email: '',
        countryId: '',
        isActived: true,
        isDefrayPermission: true,
        defrayPermissionRole: '',
        userRoleId: [],
        saleGroupValue: '',
        signFileGuid: ''
      },
      rules: {
        nameCH: [{ required: true, message: '请输入中文名', trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        password2: [{ validator: validatePass2, trigger: 'blur' }],
        phone: [{ validator: validatePhone, trigger: 'blur' }],
        email: [{ validator: validateEmail, trigger: 'blur' }],
        countryId: [
          { required: true, message: '请选择国家', trigger: 'change' }
        ],
        type: [{ required: true, message: '请选择角色', trigger: 'change' }],
        userRoleId: [
          { required: true, message: '请选择权限', trigger: 'change' }
        ],
        defrayPermissionRole: [
          { required: true, message: '请选择申付权限角色', trigger: 'change' }
        ],
        signFileGuid: [
          { required: true, message: '请上传签名文件', trigger: 'change' }
        ],
        saleGroupValue: [
          { required: true, message: '请选择销售组别', trigger: 'change' }
        ]
      },
      fingerId: '',
      isSaler: false,
      dialogVisible2: false,
      dialogVisible3: false,
      showEye1: false,
      showEye2: false,
      commData: []
    }
  },
  computed: {
    ...mapGetters(['userRole', 'addressDropOption', 'userTypeOptions', 'sign', 'userInfo']),
    userRoleIdOption: function() {
      const arr = []
      this.userRole &&
        this.userRole.map((item) => {
          arr.push({
            value: item.id,
            label: item.userRoleNameCH
          })
        })
      return arr
    },
    commOptions: function() {
      const arr = []
      this.commData.map((item) => {
        arr.push({
          label: item.description,
          value: item.dbValue
        })
      })
      return arr
    }
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        if (this.editData.id) {
          this.$refs['form']?.resetFields()
          const arr = []
          this.editData &&
            this.editData.userRole &&
            this.editData.userRole.map((item) => {
              arr.push(parseInt(item.value, 10))
            })
          const typeObj = this.userTypeOptions.find(
            (a) => a.label === this.editData.type
          )
          this.form = {
            isActived: true,
            isDefrayPermission: true,
            ...this.editData,
            userRoleId: arr,
            type: typeObj && typeObj.value
          }
          this.isSaler = typeObj?.value === 102 || typeObj?.value === 110
        } else {
          this.form = {
            type: '',
            nameCH: '',
            password: '',
            password2: '',
            nameEN: '',
            phone: '',
            email: '',
            countryId: '',
            isActived: true,
            isDefrayPermission: true,
            defrayPermissionRole: '',
            userRoleId: [],
            saleGroupValue: '',
            signFileGuid: ''
          }
          this.$refs['form']?.resetFields()
        }
      }
    }
  },
  created() {
    this.getCommData()
  },
  async mounted() {
    const key = await getKey()
    this.fingerId = key
    if (!this.sign) {
      this.$store.dispatch('getSign', key)
    }
    if (!this.userRole) {
      this.$store.dispatch('getUserRole')
    }
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
    if (!this.userTypeOption) {
      this.$store.dispatch('getUserTypeOption')
    }
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
         await this.$store.dispatch('getSign', this.fingerId)
          if (!this.form.id) {
            const obj = {
              type: this.form.type,
              password: Encrypt(this.form.password, this.sign),
              nameCH: this.form.nameCH,
              nameEN: this.form.nameEN,
              phone: this.form.phone,
              email: this.form.email,
              countryId: this.form.countryId,
              isActived: this.form.isActived,
              isDefrayPermission: this.form.isDefrayPermission,
              defrayPermissionRole: this.form.isDefrayPermission
                ? this.form.defrayPermissionRole
                : undefined,
              userRoleId: this.form.userRoleId,
              fingerId: this.fingerId,
              signFileGuid: this.form.signFileGuid,
              saleGroupValue: this.isSaler
                ? this.form.saleGroupValue
                : undefined
            }
            addUser(obj).then((res) => {
              if (res.success) {
                this.$message.success('新增成功')
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
                this.$store.dispatch('getSign', this.fingerId)
              }
            })
          } else {
            const obj = {
              type: this.form.type,
              nameCH: this.form.nameCH,
              nameEN: this.form.nameEN,
              phone: this.form.phone,
              email: this.form.email,
              countryId: this.form.countryId,
              isActived: this.form.isActived,
              isDefrayPermission: this.form.isDefrayPermission,
              defrayPermissionRole: this.form.isDefrayPermission
                ? this.form.defrayPermissionRole
                : undefined,
              userRoleId: this.form.userRoleId,
              fingerId: this.fingerId,
              signFileGuid: this.form.signFileGuid,
              id: this.form.id,
              saleGroupValue: this.isSaler
                ? this.form.saleGroupValue
                : undefined
            }
            updateUser(obj).then((res) => {
              if (res.success) {
                this.$message.success('修改成功')
                this.$emit('ok')
                if (this.userInfo.id === +this.form.id) {
                  this.$store.dispatch('getUserInfo')
                }
              } else {
                this.$message.error(res.errorMessage)
                this.$store.dispatch('getSign', this.fingerId)
              }
            })
          }
        }
      })
    },
    checkEmail(value, callback) {
      const param = {
        accountType: 1,
        validationType: 0, // 1 存在 0 不存在
        validationNumber: value
      }
      validationPhoneEmail(param).then((res) => {
        if (res.success) {
          callback()
        } else {
          callback(new Error('邮箱已存在，请重新输入'))
        }
      })
    },
    checkPhone(value, callback) {
      const param = {
        accountType: 0,
        validationType: 0, // 1 存在 0 不存在
        validationNumber: value
      }
      validationPhoneEmail(param).then((res) => {
        if (res.success) {
          callback()
        } else {
          callback(new Error('手机号码已存在，请重新输入'))
        }
      })
    },
    changeUserType(value) {
      if (value === 102 || value === 110) {
        this.isSaler = true
      } else {
        this.isSaler = false
      }
    },
    addClass() {
      this.dialogVisible2 = true
    },
    cancel2() {
      this.dialogVisible2 = false
    },
    getCommData() {
      getSysParams({ type: 10 }).then((res) => {
        console.log(res)
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push(item)
            })
          this.commData = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    dataChange() {
      this.getCommData()
    },
    beforeUpload(file) {
      const suffix = file.name.slice(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )
      if (!(suffix === 'png' || suffix === 'jpg' || suffix === 'jpeg')) {
        this.$message.warning('请上传图片格式文件')
        return false
      }
    },
    handleSuccess(e) {
      this.$set(this.form, 'signFileGuid', e.data.fileGuid)
    },
    handleError(e) {
      console.log(e)
    },
    previewImg() {
      window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${this.form.signFileGuid}`, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: calc(100% - 120px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: calc(50% - 20px);
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(2n) {
      margin-left: 40px;
    }
    .upload-demo {
      position: relative;
      width: 80px;
      height: 80px;
    }
    .upload {
      width: 80px;
      height: 80px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #eeeeee;
      font-size: 40px;
      color: #eeeeee;
      box-sizing: border-box;
    }
    .display {
      position: absolute;
      left: 0;
      top: 0;
      width: 80px;
      height: 80px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #eeeeee;
      background: #ffffff;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .trigger {
      position: absolute;
      right: -25px;
      top: 15px;
    }
    .look {
      position: absolute;
      left: 85px;
      top: 62px;
      cursor: pointer;
    }
  }
  &:deep(.el-select) {
    width: 100%;
  }
  .addClass {
    position: absolute;
    width: 84px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
    margin-left: 10px;
    cursor: pointer;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.title {
  margin-top: 30px;
  margin-block: 16px;
}
/deep/ .el-input__suffix {
  display: flex;
  align-items: center;
  width: 30px;
  padding-right: 10px;
  right: 0;
  box-sizing: border-box;
}
.suffixEye {
  font-size: 20px;
  cursor: pointer;
}
</style>
