var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.editData.id ? "修改" : "新增",
        visible: _vm.dialogVisible,
        width: "800px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "4%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("中文名"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "nameCH" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入中文名" },
                        model: {
                          value: _vm.form.nameCH,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "nameCH", $$v)
                          },
                          expression: "form.nameCH",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [_vm._v("英文名")]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "nameEN" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入英文名" },
                        model: {
                          value: _vm.form.nameEN,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "nameEN", $$v)
                          },
                          expression: "form.nameEN",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("电话"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          oninput: "value=value.replace(/[^\\d]/g,'')",
                          placeholder: "请输入电话",
                          maxlength: "11",
                        },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("邮箱"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入邮箱" },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.form.id
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v(" 密码"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                autocomplete: "off",
                                type: _vm.showEye1 ? "text" : "password",
                                placeholder:
                                  "密码长度6-18位，且必须包含数字和字母",
                              },
                              model: {
                                value: _vm.form.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "password", $$v)
                                },
                                expression: "form.password",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "suffixEye",
                                  attrs: { slot: "suffix" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showEye1 = !_vm.showEye1
                                    },
                                  },
                                  slot: "suffix",
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.showEye1,
                                        expression: "!showEye1",
                                      },
                                    ],
                                    attrs: {
                                      className: "openEye",
                                      iconClass: "openEye",
                                    },
                                  }),
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showEye1,
                                        expression: "showEye1",
                                      },
                                    ],
                                    attrs: {
                                      className: "closeEye",
                                      iconClass: "closeEye",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.form.id
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("再次输入密码"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password2" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                type: _vm.showEye2 ? "text" : "password",
                                placeholder:
                                  "密码长度6-18位，且必须包含数字和字母",
                              },
                              model: {
                                value: _vm.form.password2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "password2", $$v)
                                },
                                expression: "form.password2",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "suffixEye",
                                  attrs: { slot: "suffix" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showEye2 = !_vm.showEye2
                                    },
                                  },
                                  slot: "suffix",
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.showEye2,
                                        expression: "!showEye2",
                                      },
                                    ],
                                    attrs: {
                                      className: "openEye",
                                      iconClass: "openEye",
                                    },
                                  }),
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showEye2,
                                        expression: "showEye2",
                                      },
                                    ],
                                    attrs: {
                                      className: "closeEye",
                                      iconClass: "closeEye",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("地点"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "countryId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择国家" },
                          model: {
                            value: _vm.form.countryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "countryId", $$v)
                            },
                            expression: "form.countryId",
                          },
                        },
                        _vm._l(_vm.addressDropOption, function (item, $index) {
                          return _c("el-option", {
                            key: $index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("权限组"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "userRoleId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { multiple: "", placeholder: "请选择权限组" },
                          model: {
                            value: _vm.form.userRoleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "userRoleId", $$v)
                            },
                            expression: "form.userRoleId",
                          },
                        },
                        _vm._l(_vm.userRoleIdOption, function (item, $index) {
                          return _c("el-option", {
                            key: $index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("用户类型"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择用户类型" },
                          on: { change: _vm.changeUserType },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.userTypeOptions, function (item, $index) {
                          return _c("el-option", {
                            key: $index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item", staticStyle: { display: "flex" } },
                [
                  _c(
                    "div",
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("状态"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "isActived" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#2395F3",
                              "inactive-color": "#f1f1f1",
                              "active-text": _vm.form.isActived
                                ? "启用"
                                : "禁用",
                            },
                            model: {
                              value: _vm.form.isActived,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isActived", $$v)
                              },
                              expression: "form.isActived",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "40px" } },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("申付权限"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "isDefrayPermission" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#2395F3",
                              "inactive-color": "#f1f1f1",
                              "active-text": _vm.form.isDefrayPermission
                                ? "启用"
                                : "禁用",
                            },
                            model: {
                              value: _vm.form.isDefrayPermission,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isDefrayPermission", $$v)
                              },
                              expression: "form.isDefrayPermission",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.isSaler
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("业务组别"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "saleGroupValue" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "260px" },
                              attrs: { placeholder: "请选择业务组别" },
                              model: {
                                value: _vm.form.saleGroupValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "saleGroupValue", $$v)
                                },
                                expression: "form.saleGroupValue",
                              },
                            },
                            _vm._l(_vm.commOptions, function (item, $index) {
                              return _c("el-option", {
                                key: $index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "addClass",
                              on: { click: _vm.addClass },
                            },
                            [_vm._v("管理")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.isDefrayPermission
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("申付权限角色"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "defrayPermissionRole" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择申付权限角色" },
                              model: {
                                value: _vm.form.defrayPermissionRole,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "defrayPermissionRole",
                                    $$v
                                  )
                                },
                                expression: "form.defrayPermissionRole",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "创建人", value: 0 },
                              }),
                              _c("el-option", {
                                attrs: { label: "主管", value: 5 },
                              }),
                              _c("el-option", {
                                attrs: { label: "经理", value: 10 },
                              }),
                              _c("el-option", {
                                attrs: { label: "总监", value: 15 },
                              }),
                              _c("el-option", {
                                attrs: { label: "财务", value: 20 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.isDefrayPermission
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("label", { staticClass: "lable" }, [
                        _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                        _vm._v("签名上传"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "signFileGuid" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: `/backEnd/api/${_vm.config.supplyCode}/File/uploadFile?source=6`,
                                "on-success": (e) => _vm.handleSuccess(e),
                                "on-error": (e) => _vm.handleError(e),
                                "before-upload": (e) => _vm.beforeUpload(e),
                                "show-file-list": false,
                              },
                            },
                            [
                              _c("div", { staticClass: "upload flex_c_c" }, [
                                _c("i", { staticClass: "el-icon-plus" }),
                              ]),
                              _vm.form.signFileGuid
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "display",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: `/backEnd/api/${_vm.config.supplyCode}/File/preview?guid=${_vm.form.signFileGuid}`,
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.form.signFileGuid
                                ? _c("img", {
                                    staticClass: "trigger",
                                    attrs: {
                                      src: require("@/assets/images/const/edit.png"),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.form.signFileGuid
                        ? _c("img", {
                            staticClass: "look",
                            attrs: {
                              src: require("@/assets/images/system/look.png"),
                            },
                            on: { click: _vm.previewImg },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
      _c("CommDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible2,
          dataColumn: _vm.commOptions,
          title: "销售分组",
          type: 10,
        },
        on: {
          ok: _vm.cancel2,
          cancel: _vm.cancel2,
          dataChange: _vm.dataChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }